

/**
 * the body must have the ids of the fees to be removed from cart as array of strings
 * @param {array} feeIds 
 * @param {string} cartId
 * @returns Updated Cart
 */
export default async function RemoveFee(feeIds = [], cartId){
    const body = JSON.stringify({ ids: [feeIds] })

    return await fetch(`/checkout/api/fee?id=${cartId}`, {
            method: "DELETE",
            body,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        })
        .then(response => response.json())
        .then(data => data)
}

