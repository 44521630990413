"use client"

import { useReducer, createContext, useEffect } from 'react';
import { CartReducer, InitialState } from "./CartReducer"
import { LocalStorage, storageKeys } from 'services/LocalStorage.service';
import CreateCartAddItem from 'services/bigcommerce/CreateCartAddItem';
import AddCartItem from 'services/bigcommerce/AddCartItem';


// Global Add To Cart
export async function AddToCart(body, buyNow = false, cartId = null){
    const cart = LocalStorage.getStorage(storageKeys.cart);
    // const badCartId = "ba4b7de2-52cc-4de0-96ce-2376335f9e6f"
    // const expiredCart = "610ea838-470a-4c51-93a6-7d03f65a8e60"

    if( cart || cartId ){
        return AddCartItem(body, cartId ?? cart?.id, buyNow).then(data => data)

    }else{
        return CreateCartAddItem(body, buyNow).catch(err => err)
    }
}



/** --------------------------
 *  Cart Page Context
 *  Create our Provider with our Reducer as our state manager
 ---------------------------- */ 

export const CartContext = createContext();


export const CartContextProvider = props => {
    const [ state, dispatch ] = useReducer(CartReducer, InitialState);


    useEffect(() => {
        const storedCartData = LocalStorage.getStorage(storageKeys.cart);

        if( Array.isArray(storedCartData?.fees) ){
            dispatch({
                type: "setFees",
                data: storedCartData.fees[0]
            })
        }

    }, [])

    return (
        <CartContext.Provider value={[state, dispatch]}>
            {props.children}
        </CartContext.Provider>
    );
};

