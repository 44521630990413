/**
 * Add a fee to cart
 * @param {Object} body 
 * @param {string} cartId
 * @returns Updated Cart
 */

export default async function addFee(requestBody, cartId){
    const body = JSON.stringify(requestBody)

    return await fetch(`/checkout/api/fee?id=${cartId}`, {
            method: "POST",
            body,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            }
        })
        .then(response => response.json())
        .then(data => data)
}

